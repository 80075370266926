<template>
    <uw-content title="异常任务">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.state == null ? 'primary' : 'text'" @click="search.state = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.state == 0 ? 'primary' : 'text'" @click="search.state = 0; TableSearch()">待核实</el-button>
            <el-button size="mini" :type="search.state == 1 ? 'primary' : 'text'" @click="search.state = 1; TableSearch()">已核实</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false">

            <!-- 表数据 -->
            <template slot="data">

                <vxe-table ref="xTable" :data="table" :loading="loading" :seq-config="{startIndex: page.size * (page.current - 1)}" height="100%">
                    
                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 紧急程度 -->
                    <vxe-column width="120" field="p" title="紧急程度">
                        <template #default="{ row }">
                            <el-tag v-if="row.state == 0" size="small" effect="plain" type="danger">待核实</el-tag>
                            <el-tag v-if="row.state == 1" size="small" effect="plain" type="primary">已核实</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 工单编号 -->
                    <vxe-column width="160" field="wo.number" title="任务名称">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.taskDrawer.Open(row.task?.id)">
                                <i class="el-icon-paperclip w-margin-r-5"></i>
                                {{ row.task?.name }}
                            </span>
                        </template>
                    </vxe-column>

                    <!-- 异常描述 -->
                    <vxe-column min-width="160" field="describe" title="异常描述"></vxe-column>

                    <!-- 收单人 -->
                    <vxe-column width="110" title="核实人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.confirm_users?.avatar"> {{ row.confirm_users?.name[0] }} </el-avatar>
                                {{  row.confirm_users?.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 核实 -->
                    <vxe-column width="140" field="confirm_describe" title="核实结果/处理结果"></vxe-column>
                    <vxe-column width="140" field="confirm_at" title="核实时间/处理时间"></vxe-column>

                    <!-- 收单人 -->
                    <vxe-column width="60" title="操作" align="center">
                        <template #default="{ row }">
                            <el-link type="primary" @click="UpdateConfirm(row.id)" :disabled="!!row.state" :underline="false">核实</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <pm-projects-task-drawer ref="taskDrawer" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data() {
        return {
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                template_id: null,
                number: null,
                name: null,
                state: 0,
                projects_id: null,
                customer_id: null,
                user_sending_id: null,
                user_receive_id: null,
                range_at: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    // 计算属性
    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted() {
        this.TableSearch()
    },

    methods: {
        // 数据检索
        TableSearch() {
            this.$http.post(
                '/9api/pm/task-alarm/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['task', 'confirm_users']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 核实信息
        UpdateConfirm(id) {
            this.$prompt('核实结果/处置结果', '核实', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                this.$http.post('/9api/pm/task-alarm/update-confirm', { id: id, confirm_describe: value  }).then(rps => {
                    this.TableSearch()
                })
            })
        }
    }
}
</script>